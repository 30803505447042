/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {DateRangePicker, isInclusivelyBeforeDay} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';
import CreateCourseModal from '../../components/modals/course/createCourse';


/*Assets*/
import profile from '../../assets/images/profile.png';
import courseCover from '../../assets/images/course-cover.jpg';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class AllCourses extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: 1,
      filterTypeDefault: {value: null, label: 'All Type'},
      free: {value: null, label: 'All Type'},
      status: {value: null, label: 'All Type'},
      creator: {value: null, label: 'All Type'},
      filterTutorsDefault: {value: null, label: 'All Tutors'},
      filterTutors: {value: null, label: 'All Tutors'},
      filterStatusDefault: {value: 1, label: 'Published'},
      filterStatus: {value: 1, label: 'Published'},
      sortDefault: {value: 1, label: 'Creator name'},
      sort: {value: 1, label: 'Creator name'},
      search: "",
      data: [],
      pageNum: 1,
      searchEnable: false,
      moreOpen: 0,
      muteLoading: false,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      dataLoading: true,
      creators: []
    }
  }

  componentDidMount() {
    this.creatorsListApi(this.state.pageNum);
  }

  filterChange1 = async (free) => {
    await this.setState({free: free});
    this.creatorsListApi(this.state.pageNum);
  }

  creatorChange = async (creator) => {
    await this.setState({creator: creator});
    this.creatorsListApi(this.state.pageNum);
  }

  statusChange = async (status) => {
    await this.setState({status: status});
    this.creatorsListApi(this.state.pageNum);
  }

  creatorsListApi(tempPagenum){
    if(tempPagenum == 1){
      this.setState({dataLoading: true});
    }

    var url = devconfig.admin+"/course/list";
    var data = JSON.stringify({
      "page_num" : tempPagenum,
      "creator_id" : this.state.creator.value,
      "free" : this.state.free.value,
      "status" : this.state.status.value,
      "start_date" : this.state.startDate ? this.state.startDate.format("DD-MM-YYYY") : null,
      "end_date" : this.state.endDate ? this.state.endDate.format("DD-MM-YYYY") : null,
    })

    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Find lazy load or not*/}
        if(this.state.data.length && this.state.lazyLoadStatus){
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({data: tempCardArry});
        }
        else{
          this.setState({data: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({lazyDataEnd: true, lazyLoadStatus: false, dataLoading: false});
        }
        else{
          this.setState({lazyDataEnd: false, lazyLoadStatus: false, dataLoading: false});
        }

        // var creators = [];
        // var creatorData = response.creator_data;
        // for (var i = creatorData.length - 1; i >= 0; i--) {
        //   var temp = { label: creatorData[i].name, value: creatorData[i].id };
        //   creators.push(temp)
        // }
        // creators.unshift({ label: "All", value: null })
        // this.setState({creators: creators})

      }
      else{
        this.setState({ data: [], lazyLoadStatus: false, dataLoading: false});
      }
    }.bind(this))
  }

  loadMoreClick(){
    this.courseListApi(this.state.pageNum + 1);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
  }

  creatorsSearchApi(tempText){
    var url = devconfig.admin+"/creator/search";
    var data = JSON.stringify({
      "text" : tempText
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        this.setState({ data: response.data});

      }
      else{
        this.setState({ data: []});
      }
    }.bind(this))
  }

  searchChange(e){
    this.setState({
      search: e.target.value
    });

    if(e.target.value.length >= 3){
      this.creatorsSearchApi(e.target.value);
      this.setState({
        searchEnable: true
      });

      this.clearFilter();  
    }

    if(!e.target.value.length){
      this.creatorsListApi(1, null, 1);
      this.setState({
        searchEnable: false,
      });
    }
  }

  clearFilter(){
    this.setState({
      filterDefault: {value: null, label: 'All Creators'},
      sortDefault: {value: 1, label: 'Creator name'},
      filter: null,
      sort: 1,
    });
  }

  moreClick(tempId){
    this.setState({moreOpen: tempId});
  }

  moreClose(){
    this.setState({moreOpen: 0});
  }

 onDatesChange = async ({ startDate, endDate }) => {
      await this.setState({ 
        startDate, 
        endDate
      });
    this.creatorsListApi(this.state.pageNum);
  }

  render() {
    const filterByType = [
      { value: null, label: 'All type' },
      { value: true, label: 'Free' },
      { value: false, label: 'Paid' },
    ]
    // const filterByTutor = this.state.creators;
    const filterByTypeStatus = [
      { value: null, label: 'All status' },
      { value: true, label: 'Published' },
      { value: false, label: 'Unpublished' },
    ]
    return (
      <div className="e-main-content e-creators-wrap">
        <h2>All Courses</h2>
        <div className="row mb-4">
          <div className="col-lg-2">
            <div className="e-filter-wrap">
              <label>Filter by type:</label>
              <SelectBox defaultValue={this.state.filterTypeDefault} options={filterByType} selectchange={this.filterChange1} placeholder="Filter"/>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="e-filter-wrap">
              <label>Filter by status:</label>
              <SelectBox defaultValue={this.state.filterStatusDefault} options={filterByTypeStatus} selectchange={this.statusChange} placeholder="Filter"/>
            </div>
          </div>
          <div className="col-lg-4 mt-1">
            <div className="e-calendar-wrap mt-4 ">
                    <DateRangePicker
                      startDate={this.state.startDate}
                      endDateId="e-endDate" 
                      startDateId="e-startDate"
                      endDate={this.state.endDate}
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      startDatePlaceholderText={"Start date"}
                      endDatePlaceholderText={"End date"}
                      disableScroll={false}
                      hideKeyboardShortcutsPanel={true}
                      regular={false}
                      small
                      numberOfMonths={1}
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    />
                  </div>
          </div>
          <div className="col-lg-4 text-right mt-4">
            <button className="e-p-btn" data-toggle="modal" data-target="#createCourseModal">Create New Course</button>
          </div>
        

        </div>
        <div className="row">
          {
            this.state.dataLoading ?
              <div className="col-12 text-center">
                <LazyLoaderGif />
              </div>
            :
              this.state.data.length? 
                this.state.data.map((data, index)=>{
                  var courseCover = data.image;
                  return(
                    <Link to={"./course-detail?id=" + data.id} className="col-lg-4 e-card" key={index}>
                      <div className="e-course-item mb-4">
                        <img src={courseCover} alt="Course cover"/>
                        <div className="e-course-details">
                          <div className="text-dull">{data.module_count} modules, {data.video_count} videos</div>
                          <h5>{data.name}</h5>
                          <div>
                          {
                            data.categories && data.categories.length?
                              data.categories.map((tag, index)=>{
                                return(
                                  <span className="e-category-tag">{tag.category}</span>
                                )
                              })
                            :
                              null
                          }
                          </div>
                          <div className="row">
                            <div className="col-lg-6 e-course-figures">
                              <span className="text-dull">Enrolled: </span>
                              <p><b>{data.enrolled_count}</b></p>
                            </div>
                            <div className="col-lg-6 e-course-figures">
                              <span className="text-dull">Cost: </span>
                              <p>₹{data.price}/ <b>₹{data.offer_price}</b></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })
            :
              <NothingFound />
          }
        </div>

        {
          !this.state.lazyDataEnd && !this.state.lazyLoadStatus ?
            <div className="col-lg-12 text-center py-3">
              <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
            </div>
          :
            null
        }

        {
          this.state.lazyLoadStatus ?
            <div className="col-lg-12 text-center py-3">
              <LazyLoaderGif/>
            </div>
          :
            null
        }

        <CreateCourseModal />
      </div>
      
    );
  }
}

export default AllCourses;
