/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import EditBankModal from '../../components/modals/profile/editBank';
import EditProfileModal from '../../components/modals/profile/editProfile';
import LazyLoaderGif from '../../components/common/lazyloader';

/*Assets*/
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';
import profile from '../../assets/images/profile.png';

class Profile extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      profileLoading: true,
      data: {},
      bankData: null,
      payments: null,
      type: 1
    }
  }

  componentDidMount() {
    this.getProfile();
    
    var tempLocal = JSON.parse(localStorage.getItem("u_data"));
    this.setState({type: tempLocal.type});

    if(tempLocal.type != 1){
      this.getBankDetails();
      this.getPaymentHistory();
    }
  }
  getProfile(){
    var url = devconfig.admin + "/auth/profile";
    APIGet(url).then((response)=>{
      if(response.status){
        this.setState({
          data: response.data,
          profileLoading: false
        })

        if(response.data.type != this.state.type){
          localStorage.setItem("u_data", JSON.stringify(response.data));
          window.location.reload();
        }
      }else{
        this.setState({loading: false});
      }
    })
  }
  getBankDetails(){
    var url = devconfig.admin + "/auth/get-bank-details";
    APIGet(url).then((response)=>{
      if(response.status){
        this.setState({
          bankData: response.data,
          loading: false
        })
      }else{
        this.setState({loading: false});
      }
    })
  }
  getPaymentHistory(){
    var url = devconfig.admin + "/course/payments";
    APIGet(url).then((response)=>{
      if(response.status){

        console.log("Insinde getPaymentHistory");
        console.log(response);

        this.setState({
          payments: response.data,
          loading: false
        })
      }
      else{
        // alert("Network error");
        // this.setState({loading: false});
      }
    })
  }
  

  render() {
    var data = this.state.data;
    var bankData = this.state.bankData;
    return(
      <div className="e-main-content">
        <h2>Profile</h2>

        {
          this.state.loading ?
            <div className="row text-center">
              <div className="col-lg-12 text-center">
                <LazyLoaderGif />
              </div>
            </div>
          :
            <React.Fragment>
              <div className="row">
                <div className="col-lg-6">
                  <div className="e-profile-item">
                    <h6>Basic Info</h6>
                    <div className="media e-detail-profile-wrap">
                      <img className="mr-3" src={data.image ? data.image : profPlaceholder} alt="Profile"/>
                      <div className="media-body">
                        <div className="e-detail-profile-top">
                          <div className="d-flex justify-content-between">
                            <h5 className="m-0">{data.name}</h5>
                            <button className="e-s-btn e-edit-btn" data-toggle="modal" data-target="#editProfileModal">Edit</button>   
                          </div>
                          {
                            data.email ?
                              <div className="e-email mt-2">{data.email}</div>
                            :
                              <div>
                                <span className="e-link-txt mt-2" data-toggle="modal" data-target="#editProfileModal">+ Add email</span>
                              </div>
                          }
                          {
                            data.mobile ?
                              <div className="e-email mt-2">{data.mobile}</div>
                            :
                              <div>
                                <span className="e-link-txt mt-2" data-toggle="modal" data-target="#editProfileModal">+ Add mobile</span>
                              </div>
                          }
                          {
                            data.designation ?
                              <div className="e-email mt-2">{data.designation}</div>
                            :
                              <div>
                                <span className="e-link-txt mt-2" data-toggle="modal" data-target="#editProfileModal">+ Add designation</span>
                              </div>
                          }
                          {
                            data.pan_no ?
                              <div className="e-email mt-2">{data.pan_no}</div>
                            :
                              <div>
                                <span className="e-link-txt mt-2" data-toggle="modal" data-target="#editProfileModal">+ Add PAN Number</span>
                              </div>
                          }
                          {
                            data.description ?
                              <div className="e-email mt-2">{data.description}</div>
                            :
                              <div>
                                <span className="e-link-txt mt-2" data-toggle="modal" data-target="#editProfileModal">+ Add description</span>
                              </div>
                          }
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              {
                this.state.type != 1 ?
                  <React.Fragment>

                    <div className="row">
                      {
                        this.state.payments ?
                          <div className="col-lg-6 mt-4">
                            <div className="e-profile-item e-profile-courses">
                              <h6>Courses Payments</h6>
                              <div className="row">
                                <div className="col-lg-12">
                                  {
                                    this.state.payments ?
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Courses name</th>
                                            <th>Total amount</th>
                                            <th>Total incentive</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          { 
                                            this.state.payments.map( (data, index) => {
                                              return(
                                                <tr className="" key={index}>
                                                  <td>{data.name}</td>
                                                  <td>₹ {data.total_course_amount}</td>
                                                  <td>₹ {data.total_incentive_amount}</td>
                                                </tr>
                                              );
                                            })
                                          }
                                        </tbody>
                                      </table>
                                    :
                                      <p>No course incentive details found!</p>
                                  }
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        :
                          null
                      }

                      {
                        this.state.bankData ?
                          <div className="col-lg-6 mt-4">
                            <div className="e-profile-item e-profile-bank">
                              <h6>Bank Details</h6>
                              <div className="row">
                                <button className="e-s-btn e-edit-btn" data-toggle="modal" data-target="#addBankModal">Edit</button>  
                                <div className="col-lg-4">
                                  <span className="text-dull">Beneficiary name</span>
                                  <p>{bankData.name}</p>
                                </div>
                                <div className="col-lg-4">
                                  <span className="text-dull">Account number</span>
                                  <p>{bankData.account_no}</p>
                                </div>
                                <div className="col-lg-4">
                                  <span className="text-dull">Bank name</span>
                                  <p>{bankData.bank}</p>
                                </div>
                                <div className="col-lg-4">
                                  <span className="text-dull">IFSC code</span>
                                  <p>{bankData.ifsc_code}</p>
                                </div>
                                <div className="col-lg-4">
                                  <span className="text-dull">Branch</span>
                                  <p>{bankData.branch}</p>
                                </div>
                                {
                                  data ?
                                    <React.Fragment>
                                      <div className="col-lg-12"><hr/></div>
                                      <div className="col-lg-4">
                                        <span className="text-dull">Incentive percentage</span>
                                        <p>{data.incentive_percentage ? data.incentive_percentage : 0} %</p>
                                      </div>
                                      <div className="col-lg-4">
                                        <span className="text-dull">Total incentive</span>
                                        <p>₹ {data.total_incentive_amount ? data.total_incentive_amount : 0}</p>
                                      </div>
                                      <div className="col-lg-4">
                                        <span className="text-dull">Pending incentive</span>
                                        <p>₹ {data.pending_incentive_amount ? data.pending_incentive_amount : 0}</p>
                                      </div>
                                    </React.Fragment>
                                  :
                                    null
                                }
                                
                              </div>
                            </div>
                          </div>
                        :
                          <div className="col-lg-6 mt-4">
                            <div className="e-bank-note d-flex justify-content-between">
                              <p>
                                Add bank details to view incentive!
                              </p>
                              <span>
                                <button form="e-login-form" value="Submit" data-toggle="modal" data-target="#addBankModal">Add bank</button>
                              </span>
                            </div>
                          </div>
                      }
                      
                    </div>

                  </React.Fragment>
                :
                  null
              }
            </React.Fragment>
        }

      {/*Modals*/}
      { this.state.loading ? null : this.state.bankData ? <EditBankModal data={this.state.bankData} /> : <EditBankModal/> }
      { this.state.profileLoading ? null : <EditProfileModal data={this.state.data} /> }

      </div>
    )
  }
}

export default Profile;
