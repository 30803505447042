/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Input extends Component {
  constructor(props){
    super(props);
    this.state = {
    	value: ""
    }
  }

  componentDidMount(){
    if(this.props.defaultValue){
      this.setState({value: this.props.defaultValue});
    }
  }

  valueChange(e) {
    this.setState({value: e.target.value});
    this.props.valueChange(e.target.value);
  }

  render() {
    return (
      <div className="e-input-wrap">
        <input 
          className={this.props.class} 
          disabled={this.props.disabled} 
          id={this.props.id} 
          value={this.props.value} 
          type={this.props.type} 
          onChange={this.valueChange.bind(this)} 
          required
          {...this.props}
        />
        <label htmlFor={this.props.id} className="label">{this.props.label}</label>
      </div>
    );
  }
}

export default Input;
