/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';


/*Components*/
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';

/*Assets*/

class AddVideo extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: "",
      apiLoading: false,
      catFilterOption: [],
      langFilterOption: [],
      segFilterOption: [],
      showUploadLoader: false,
      link: ""
    }
  }

  componentDidMount() {
    this.getCategory();
    this.getLanguage();
    this.getSegments();
  }

  getCategory(){
    var url = devconfig.admin+"/category/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });
        
        this.setState({catFilterOption: tempArry});
      }
    }.bind(this))
  }

  getSegments(){
    var url = devconfig.admin+"/segment/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.segment};
          tempArry.push(item);
        });
        
        this.setState({segFilterOption: tempArry});
      }
    }.bind(this))
  }

  getLanguage(){
    var url = devconfig.admin+"/language/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.language};
          tempArry.push(item);
        });
        
        this.setState({langFilterOption: tempArry});
      }
    }.bind(this))
  }

  catSelectChange = selectedValue => {
    this.setState({ selectedValue });
  };

  langSelectChange = selectedLangValue => {
    this.setState({ selectedLangValue });
  };
  
  segSelectChange = selectedSegValue => {
    this.setState({ selectedSegValue });
  };

  addVideo(){
    this.setState({apiLoading: true});

    var tempCategoryIds = [];
    this.state.selectedValue.map(function(item, index){
      tempCategoryIds.push(item.value);
    });

    var url = devconfig.admin+"/webinar/add-existing-webinar";
    var data = JSON.stringify({
      "name" : this.state.name,
      "category_id" : tempCategoryIds,
      "segment_id" : this.state.selectedSegValue.value,
      "language_id" : this.state.selectedLangValue.value,
      "link" : this.state.link,
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({apiLoading: false});
        $('#addVideoModal').modal('hide');
        this.props.success();
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  render() {

    const {selectedValue, selectedLangValue, selectedSegValue} = this.state;
    
    return (
      <div className="modal fade" id="addVideoModal" tabIndex="-1" role="dialog" aria-labelledby="addVideoModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Old Videos</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="e-modal-s-txt">Add your old videos to Trade School.</div>
            <div className="modal-body mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Webinar Name" id="u-name" type="text" defaultValue={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="e-add-video-select-wrap e-select-multi">
                    <Select isSearchable={false} options={this.state.catFilterOption} value={selectedValue} onChange={this.catSelectChange} isMulti={true} placeholder=""/>
                    <label>Choose Category</label>
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className="e-add-video-select-wrap e-select-multi">
                    <Select isSearchable={false} options={this.state.langFilterOption} value={selectedLangValue} onChange={this.langSelectChange} isMulti={false} placeholder=""/>
                    <label>Choose Language</label>
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className="e-add-video-select-wrap e-select-multi">
                    <Select isSearchable={false} options={this.state.segFilterOption} value={selectedSegValue} onChange={this.segSelectChange} isMulti={false} placeholder=""/>
                    <label>Choose Language</label>
                  </div>
                </div>

                <div className="col-lg-12">
                  <Input label="Video Link" id="u-link" type="text" defaultValue={this.state.link} valueChange={(tempVal) => this.setState({link: tempVal})} />
                </div>

                <div className="col-lg-12">
                  <span className="pt-2 text-danger">{this.state.errorMessage}</span>
                </div>

                <div className="col-lg-12 mt-4">
                  <div className="text-right">
                    <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                      {
                        this.state.selectedValue && this.state.selectedLangValue && this.state.selectedSegValue && this.state.name.length && this.state.link.length && !this.state.apiLoading ?
                          <button type="submit" form="e-login-form" onClick={this.addVideo.bind(this)}>Add Video</button>
                        :
                          <button className="e-inactive" form="e-login-form" value="Submit">Add Video</button>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default AddVideo;
