/*Package importing*/
import React, { Component } from 'react';


/*Components*/

/*Assets*/

class VideoPlayerModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  
  render() {
    
    return (
      <div className="modal fade e-modal e-video-modal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title py-3">{this.props.data.name}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <video width="320" height="240" controls>
                    <source src={this.props.data.video_url} type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default VideoPlayerModal;
