/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';


/*Components*/
import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/textarea';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';
import ImageUploader from '../../../components/common/imageUploader';
import UploadLoader from '../../../components/common/uploadLoader';

/*Assets*/

class EditProfileModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      mobile: "",
      link: "",
      description: "",
      designation: "",
      apiLoading: false,
      scheduleTime: "",
      catFilterOption: [],
      langFilterOption: [],
      showUploadLoader: false,
      logoURL: "",
      value: null,
      duration: null,
      selectedPriceDiscountValue: {value: null, label: 'No discount'}
    }
  }

  componentDidMount() {
    var params = this.props.data;
    if(params){
      this.setState({
        name: params.name,
        mobile: params.mobile,
        logoURL: params.image,
        description: params.description,
        designation: params.designation,
        pan: params.pan_no
      })

    }
  }

  updateProfile(){
    this.setState({apiLoading: true});
    var url = devconfig.admin + "/auth/update-profile";
    var data = JSON.stringify({
      name: this.state.name,
      mobile: this.state.mobile,
      image: this.state.logoURL,
      description: this.state.description,
      designation: this.state.designation,
      pan_no: this.state.pan,
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        this.setState({apiLoading: false});
        window.location.reload();
      }else{
        // alert("Network error");
        this.setState({apiLoading: false, errorMessage: response.message});
      }
    })
  }

  logoUploaded(tempURL){
    this.setState({logoURL: tempURL, showUploadLoader: false});
  }

  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: true});
  }

  render() {
    const {selectedValue, selectedLangValue, duration, value, selectedPriceDiscountValue} = this.state;

    const priceDiscounts = [
      { value: null, label: 'No discount' },
      { value: 1, label: '10%' },
      { value: 2, label: '20%' },
    ]
    
    return (
      <div className="modal fade e-modal" id="editProfileModal" tabIndex="-1" role="dialog" aria-labelledby="editProfileModalLabel" aria-hidden="true">
        <div className="modal-dialog e-edit-profile-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title py-3">Edit Profile Details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-4 pb-3">
                  {
                    this.state.showUploadLoader ?
                      <div className="e-upload-btn text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                        <UploadLoader/>
                      </div>
                    :
                      <div>
                        {
                          this.state.logoURL && this.state.logoURL.length ?
                            <div className="e-upload-btn e-image-preview-wrap text-center position-relative" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                              <img src={this.state.logoURL}/>
                              <label className="e-change">Change</label>
                            </div>
                          :
                            <div className="e-upload-btn text-center e-upload-main-btn" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                              <span>Click to upload your profile image</span>
                            </div>
                        }
                        
                      </div>
                  }
                  <ImageUploader id="e-logo-upload" uploadSuccess={this.logoUploaded.bind(this)}/>
                </div>
                <div className="col-lg-12">
                  <Input label="Your name" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Phone number" id="u-phone" type="text" value={this.state.mobile} valueChange={(tempVal) => this.setState({mobile: tempVal})} />
                </div>
                <div className="col-lg-12 e-text-wrap">
                  <Textarea label="Brief about you" id="u-brief" type="text" value={this.state.description} valueChange={(tempVal) => this.setState({description: tempVal})} />
                </div>

                <div className="col-lg-12">
                  <Input label="Designation" id="u-phone" type="text" value={this.state.designation} valueChange={(tempVal) => this.setState({designation: tempVal})} />
                </div>

                <div className="col-lg-12">
                  <Input label="PAN Number" id="u-phone" type="text" value={this.state.pan} valueChange={(tempVal) => this.setState({pan: tempVal})} />
                </div>

                
                <div className="col-lg-12">
                  <div className="e-addPromo-btn-wrap text-right pt-2">
                    <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                    {
                      this.state.logoURL && this.state.logoURL.length && this.state.designation.length && this.state.description.length && this.state.mobile.length && this.state.name.length && !this.state.apiLoading ?
                        <button type="submit" form="e-login-form" onClick={this.updateProfile.bind(this)}>Update</button>
                      :
                        <button className="e-inactive" form="e-login-form" value="Submit">Update</button>
                    }
                  </div>
                </div>
              </div>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditProfileModal;
