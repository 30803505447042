/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';
import moment from 'moment';

/*Import custom components and assets*/
import Input from '../formComponents/input';
import APIPost from '../../services/apiCall/post';
import DurationValidation from '../../services/validation/duration';
import UploadFileAWS from './awsHelper';

class Video extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: "",
      saved: false
    }
  }

  componentDidMount(){
    var video = this.props.data;
    var moduleId = this.props.moduleId;
    var courseId = this.props.courseId;
    var free = video.free;

    if(video.video_url.length){
      this.setState({
        saved: true
      })
    }
    if(free){
      this.setState({
        selectedVideoValue: { value: 2, label: 'Free' }
      })
    }else{
      this.setState({
        selectedVideoValue: { value: 1, label: 'Paid' }
      })
    }
    this.setState({
      name: video.name,
      video_url: video.video_url,
      duration_string: video.duration_string,
      free: video.free,
      moduleId: moduleId,
      courseId: courseId,
      video_id: video.id
    })


    // file change
    $(document).ready(()=>{
      document.getElementById(video.id).onchange = (e) => {
        var file = e.target.files[0];
        this.prepareUpload(file);
      };
    })
  }

  valueChange(e) {
    this.setState({value: e.target.value});
    this.props.valueChange(e.target.value);
    this.props.buttonDisable();
  }

  openFileExplorer(elemId){
    this.props.buttonDisable();
    this.setState({errorMessage: "", saveInative: true});
    var elem = document.getElementById(elemId);
    if(elem && document.createEvent) {
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("click", true, false);
        elem.dispatchEvent(evt);
    }
  }

  async getVideoURL(tempFile){
    var tempFileNameSplit = tempFile.name.split(".")
    var tempCurrentDate = moment().format();
    tempCurrentDate = tempCurrentDate.slice(0,-1);
    var tempFilName = tempFileNameSplit[0];
    tempFilName = tempFilName.replace(" ", "_");
    tempFilName = "videos/" + this.state.courseId + "/" + tempFilName
    return tempFilName;
  }

  async prepareUpload(file){
    var tempFileName = file.type
    var fileType = tempFileName.split("/").pop();
    var videoURL = await this.getVideoURL(file);
    await this.uploadFile(file, fileType, tempFileName, videoURL)
  }

  async uploadFile(file, fileType, tempFileName, path){
    this.setState({video_uploading: true});
    UploadFileAWS(tempFileName, path, file, fileType).then(function(url){
      this.setState({video_url: url, video_uploading: false, saved: false, uploadToggle: false});
      // callback to parent 
      // update video url
      // this.props.addVideo({
      //   id: false, 
      //   video_url: url, 
      //   duration_sec: this.state.duration, 
      //   duration_string: this.state.duration_string, 
      //   free: this.state.selectedVideoValue.value==2,
      //   active: true,
      //   name: this.state.name
      // });
    }.bind(this))
  }

  catSelectChange = selectedVideoValue => {
    this.props.buttonDisable();
    this.setState({ selectedVideoValue: selectedVideoValue, errorMessage: "", saved: false });
  };

  async addIndVideo(){
    var tempValidation = await DurationValidation(this.state.duration_string);
    if(tempValidation){
      this.setState({saved: true});
      if(this.state.name && this.state.duration_string && this.state.selectedVideoValue.value){

        this.props.addVideo({
          id: this.state.video_id, 
          video_url: this.state.video_url, 
          duration_sec: this.state.duration, 
          duration_string: this.state.duration_string, 
          free: this.state.selectedVideoValue.value==2,
          active: true,
          name: this.state.name
        })
      }
      else{
        this.setState({errorMessage: "Please fill the details before saving the video"});
      }
    }
    else{
      this.setState({errorMessage: "Duration must be in HH:MM format (eg: 03:30, 00:45)", duration_string: ""});
    }
  }

  deleteVideo(){
    this.props.deleteVideo(this.state.video_id);
    // this.setState({deleted: true});
  } 

  async changeVideo(){
    $("#"+this.state.video_id).prop("value", "");
    await this.setState({uploadToggle: true, video_url: "", saved: false});



    this.props.addVideo({
      id: this.state.video_id, 
      video_url: this.state.video_url, 
      duration_sec: this.state.duration, 
      duration_string: this.state.duration_string, 
      free: this.state.selectedVideoValue.value==2,
      active: true,
      name: this.state.name
    })
  }

  async nameChange(tempVal){
    this.setState({name: tempVal, errorMessage: "", saved: false} )

    console.log("Name change");
    console.log(this.state.name);

    // this.props.addVideo({
    //   id: this.state.video_id, 
    //   video_url: this.state.video_url, 
    //   duration_sec: this.state.duration, 
    //   duration_string: this.state.duration_string, 
    //   free: this.state.selectedVideoValue.value==2,
    //   active: true,
    //   name: this.state.name
    // })
  }

  render() {
    const videoType = [
      { value: 1, label: 'Paid' },
      { value: 2, label: 'Free' },
    ]
    return (
      <div className="col-lg-6">
        <div className="e-module-details-wrap">
          <span className="e-s-link-txt e-delete-link mr-4 e-video-delete" onClick={()=>this.deleteVideo()}></span>
          <Input label={"Video "+ (Number(this.props.index) + 1) +" title"} id="v-title" type="text" value={this.state.name} valueChange={this.nameChange.bind(this)}/> {/* valueChange={(tempVal) => this.setState({name: tempVal, errorMessage: "", saved: false} )*/}
          <div className="e-video-upload-wrap mb-3">
            <label>Upload video (Support mp4, mpeg). Max size 500MB</label>
            {
              !this.state.video_uploading?
                this.state.video_url && !this.state.uploadToggle ?
                  <div className="e-video-upload-btn e-uploaded mt-3 postion-relative">
                    <div className="e-video-url-ellipse">{this.state.video_url}</div>
                    <span onClick={()=>this.changeVideo()}>Change</span>
                  </div>
                :
                  <div className="e-video-upload-btn e-video-upload-btn-inital" onClick={()=>this.openFileExplorer(this.state.video_id)}> Click to upload</div>
              :
                <div className="e-video-upload-btn e-upload-loader mt-3"></div>

            }

            <input type="file" accept="video/mp4,video/x-m4v,video/*" className="hidden" id={this.state.video_id} />
            
            
            {/*Uploading Loader */}
            {/*<div className="e-video-upload-btn e-upload-loader mt-3"></div>*/}

            {/* After successful upload*/}
            {/*<div className="e-video-upload-btn e-uploaded mt-3 postion-relative">
              www.filesource/filename.mpeg
              <span>Change</span>
            </div>*/}
          </div>
          <div className="row">
            {/* Remove duration col if you find the duration from the uploaded file itself  */}
            <div className="col-lg-6">
              <Input label="Video duration(HH:MM)" id="v-duration" type="text" value={this.state.duration_string} placeholder="Eg: 00:30" valueChange={(tempVal) => this.setState({duration_string: tempVal, errorMessage: "", saved: false})} />
            </div>
            <div className="col-lg-6">
              <div className="e-add-video-select-wrap text-left">
                <Select isSearchable={false} options={videoType} value={this.state.selectedVideoValue} onChange={this.catSelectChange} isMulti={false} placeholder=""/>
                <label>Video Type</label>
              </div>
            </div>
          </div>
          <div className="row no-padding e-video-note">
            
            {
              this.state.errorMessage ?
                <div className="col-lg-11 e-c-red e-video-error no-padding">
                  {this.state.errorMessage}
                </div>
              :
                null
            }
            
          </div>
          <div className="row no-padding">
            <div className="no-padding col-lg-4 pull-right">
              {
                !this.state.saved ?
                  <React.Fragment>
                    {
                     (this.state.video_url && this.state.video_url.length) && 
                     (this.state.name && this.state.name.length) && 
                     (this.state.duration_string && this.state.duration_string.length) ?
                        <button className="handn e-p-btn e-p-btn-s" onClick={this.addIndVideo.bind(this)}>Save</button>
                      :
                        <button className="handn e-p-btn e-p-btn-s e-inactive" form="e-login-form" value="Submit">Save</button>
                    }
                  </React.Fragment>
                :
                  <span className="e-saved-tag">Saved</span>
              }
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default Video;