/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';
import moment from 'moment';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import TimePicker from 'rc-time-picker';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';

/*Components*/
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import ImageUploader from '../../components/common/imageUploader';
import UploadLoader from '../../components/common/uploadLoader';

/*Assets*/

class EditPromo extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      link: "",
      apiLoading: false,
      scheduleTime: "",
      catFilterOption: [],
      langFilterOption: [],
      segFilterOption: [],
      showUploadLoader: false,
      logoURL: "",
      value: null,
      duration: null,
    }
  }

  componentDidMount() {
    this.getCategory();
    this.getLanguage();
    this.getSegments();

    var tempDateSplit = this.props.data.scheduled_date.split(" ");
    var tempDate = moment(this.props.data.scheduled_time)._i;

    var tempCategoryIds = [];
    this.props.data.category_ids.map(function(item, index){
      var tempArry = {value: item.id, label: item.category}
      tempCategoryIds.push(tempArry);
    });


    this.setState({ 
      duration: moment(this.props.data.duration, 'HH:mm a'),
      name: this.props.data.name,
      logoURL: this.props.data.thumbnail,
      selectedValue: tempCategoryIds,
      selectedLangValue: {value: this.props.data.language_id, label: this.props.data.language},
      selectedSegValue: {value: this.props.data.segment_id, label: this.props.data.segment},
      data: moment(this.props.data.scheduled_date),
      value: moment(tempDateSplit[1], 'HH:mm a'),
      date: moment(tempDate)
    });

    setTimeout(function(){
      $('#editPromoModal').modal('show');
    }.bind(this),100);
  }

  getCategory(){
    var url = devconfig.admin+"/category/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });
        
        this.setState({catFilterOption: tempArry});
      }
    }.bind(this))
  }

  getSegments(){
    var url = devconfig.admin+"/segment/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.segment};
          tempArry.push(item);
        });
        
        this.setState({segFilterOption: tempArry});
      }
    }.bind(this))
  }

  getLanguage(){
    var url = devconfig.admin+"/language/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.language};
          tempArry.push(item);
        });
        
        this.setState({langFilterOption: tempArry});
      }
    }.bind(this))
  }

  handleTimeChange = value => {
    if(value){
      this.setState({ value, scheduleTime: value.format('HH:mm')});
    }
  };

  handleDurationChange = duration => {
    if(duration){
      this.setState({ duration,videoDuration: duration.format('HH:mm:ss')});
    }
  };

  catSelectChange = selectedValue => {
    this.setState({ selectedValue });
  };

  langSelectChange = selectedLangValue => {
    this.setState({ selectedLangValue });
  };

  segSelectChange = selectedSegValue => {
    this.setState({ selectedSegValue });
  };
  

  logoUploaded(tempURL){
    this.setState({logoURL: tempURL, showUploadLoader: false});
  }

  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: true, logoURL: ""});
  }

  addPromo(){
    this.setState({apiLoading: true});
    var date = new Date(this.state.date._d);
    var tempStartDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' +  date.getFullYear();
    var tempTime = this.state.value.format('HH:mm');

    var tempDateTime = tempStartDate +" "+tempTime;
    var tempDuration = this.state.duration.format('HH:mm:ss');

    var url = devconfig.admin+"/webinar/update-promo";

    var tempArry = [];
    this.state.selectedValue.map(function(item, index){
      tempArry.push(item.value);
    });

    var data = JSON.stringify({
      "name" : this.state.name,
      "category_id" : tempArry,
      "language_id" : this.state.selectedLangValue.value,
      "segment_id" : this.state.selectedSegValue.value,
      "scheduled_time" : tempDateTime,
      "duration" : tempDuration,
      "thumbnail" : this.state.logoURL,
      "video_id" : this.props.data.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({apiLoading: false});
        $('#editPromoModal').modal('hide');
        this.props.success();
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  editCloseClick(){
    this.props.editclose();
  }

  render() {
    const {selectedValue, selectedLangValue, duration, value, selectedSegValue} = this.state;

    const filterBy = [
      { value: null, label: 'All Creators' },
      { value: 1, label: 'Top 10' },
      { value: 2, label: 'Muted' },
    ]
    
    return (
      <div className="modal fade" id="editPromoModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="editPromoModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Webinar Promo</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.editCloseClick.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="e-modal-s-txt">Fill out the details of the webinar.</div>
            <div className="modal-body mt-3">
              <div className="row">
                <div className="col-lg-12">
                {
                  this.state.name.length ?
                    <Input label="Webinar Name" id="u-name" type="text" defaultValue={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                  :
                    null
                }
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="e-add-video-select-wrap e-select-multi">
                    <Select isSearchable={false} options={this.state.catFilterOption} value={selectedValue} onChange={this.catSelectChange} isMulti={true} placeholder=""/>
                    <label>Choose Category</label>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="e-add-video-select-wrap">
                    <Select isSearchable={false} options={this.state.langFilterOption} value={selectedLangValue} onChange={this.langSelectChange} isMulti={false} placeholder=""/>
                    <label>Choose Language</label>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div className="e-add-video-select-wrap">
                    <Select isSearchable={false} options={this.state.segFilterOption} value={selectedSegValue} onChange={this.segSelectChange} isMulti={false} placeholder=""/>
                    <label>Choose Segment</label>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="e-datePicker-wrap">
                    {
                      this.state.date ?
                        <SingleDatePicker
                          date={this.state.date}
                          onDateChange={date => this.setState({ date })}
                          focused={this.state.focused}
                          onFocusChange={({ focused }) => this.setState({ focused })}
                          id="your_unique_id"
                          disableScroll={false}
                          numberOfMonths={1}
                          hideKeyboardShortcutsPanel={true}
                          enableOutsideDays={false}
                          placeholder=""
                        />
                      :
                        null
                    }

                    
                    <label>Schedule Date</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="e-timePicker-wrap">
                    {/*<TimePicker showSecond={false} value={this.state.scheduleTime} onChange={(value) => this.setState({scheduleTime: value})} />*/}
                    <TimePicker value={value} onChange={this.handleTimeChange} showSecond={false} />
                    <label>Schedule Time</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="e-timePicker-wrap">
                    {/*<TimePicker showSecond={false} value={this.state.scheduleTime} onChange={(value) => this.setState({scheduleTime: value})} />*/}
                    <TimePicker className="e-expected-timePicker" value={duration} onChange={this.handleDurationChange} />
                    <label>Expected Duration (Hr:Min:Sec)</label>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-6">

                      {
                        this.state.showUploadLoader ?
                          <div className="e-upload-btn text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                            <UploadLoader/>
                          </div>
                        :
                          <div className="position-relative">
                            {
                              this.state.logoURL.length ?
                                <div className="e-upload-btn e-image-preview-wrap text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                                  <img src={this.state.logoURL}/>

                                  <span className="e-change">Change</span>
                                </div>
                              :
                                <div className="e-upload-btn text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                                  <span>Upload banner</span>
                                </div>
                            }
                            
                          </div>
                      }
                      
                      <ImageUploader id="e-logo-upload" uploadSuccess={this.logoUploaded.bind(this)}/>
                    </div>
                    <div className="col-lg-6">
                      <div className="e-addPromo-btn-wrap text-right">
                        <span className="e-s-link-txt mr-4" data-dismiss="modal" onClick={this.editCloseClick.bind(this)}>Cancel</span>
                        {
                          this.state.logoURL.length && this.state.duration && this.state.value && this.state.date && this.state.selectedValue && this.state.selectedSegValue && this.state.selectedLangValue && this.state.name.length && !this.state.apiLoading ?
                            <button type="submit" form="e-login-form" onClick={this.addPromo.bind(this)}>Update Promo</button>
                          :
                            <button className="e-inactive" form="e-login-form" value="Submit">Update Promo</button>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditPromo;
