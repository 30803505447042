/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';

/*Assets*/
import profile from '../../assets/images/profile.png';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class UsersList extends Component {
  constructor(props){
    super(props);
    this.state = {
      sort: 1,
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      pageNum: 1,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      downloadLoading: false,
      dataLoading: false
    }
  }

  componentDidMount() {
    this.usersListApi();
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.usersListApi(this.state.pageNum, sort.value);
  }

  usersListApi(){
    this.setState({dataLoading: true});
    var url = devconfig.admin+"/users/leads-generated";
    

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({data: response.data, dataLoading: false});
      }
      else{
        this.setState({ data: [], dataLoading: false});
      }
    }.bind(this))
  }
  
  loadMoreClick(){
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    this.creatorsListApi(this.state.pageNum + 1, this.state.filter, this.state.sort);
  }

  

  render() {
    const sortBy = [
      { value: 1, label: 'Name' },
      { value: 2, label: 'Top Viewed' },
      { value: 3, label: 'Latest Joind ' },
      { value: 4, label: 'Recent Activity' },
    ]
    return (
      <div className="e-main-content e-creators-wrap">
        <h2>Leads Generated</h2>
        {
          this.state.dataLoading ?
            <div className="row">
              <div className="col-lg-12 text-center py-3">
                <LazyLoaderGif/>
              </div>
            </div>
          :
            <div className="row mt-4">
              {
                this.state.data.length ?
                  <div className="col-lg-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Video Name</th>
                          <th>Leads Generated</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          this.state.data.map( (data, index) => {
                            return(
                              <tr key={index}>
                                <td>
                                  <span className="e-creator-name">{data.name}</span>
                                </td>
                                <td className="e-creator-dull-txt text-left">{data.leads_generated}</td>
                                
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                    {
                      this.state.moreOpen ?
                        <span className="e-more-overlay" onClick={this.moreClose.bind(this)}></span>
                      :
                        null
                    }

                    {
                      !this.state.lazyDataEnd ?
                        <div className="text-center py-3">
                          <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                        </div>
                      :
                        null
                    }

                    {
                      this.state.lazyLoadStatus ?
                        <div className="text-center py-3">
                          <LazyLoaderGif/>
                        </div>
                      :
                        null
                    }
                  </div>
                :
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No leads or videos found !"/>
                  </div>
              }
            </div>
        }
        
      </div>
      
    );
  }
}

export default UsersList;
