/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import SelectBox from '../../components/common/selectBox';
import LazyLoaderGif from '../../components/common/lazyloader';
import RemovePromo from '../../components/modals/removePromo';

import NothingFound from '../../components/common/nothingFound';

/*Assets*/
import profile from '../../assets/images/profile.png';

class Upcoming extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: null,
      filterDefault: {value: null, label: 'All Videos'},
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      categoryFilter: [],
      dataLoading: false,
      deleteItem: null
    }
  }

  componentDidMount() {
    this.upcomingApi(null, 1);
  }

  upcomingApi(tempFilter, tempSort){
    this.setState({ dataLoading: true});
    var url = devconfig.admin+"/webinar/upcoming-webinars";
    var data = JSON.stringify({
      "category_filter" : this.state.filter,
      "sort_value" : this.state.sort
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        tempArry.push({ value: null, label: 'All Videos' });
        response.category_filter.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });

        this.setState({ data: response.data, categoryFilter: tempArry, dataLoading: false});
      }
      else{
        this.setState({ data: [], dataLoading: false});
      }
    }.bind(this))
  }
  

  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.upcomingApi(filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.upcomingApi(this.state.filter, sort.value);
  }

  editClick(tempData){
    this.props.edit(tempData);
  }

  addLinkClick(tempData){
    this.props.addlink(tempData);
  }

  deleteClick(tempData){
    this.setState({ deleteItem: tempData });
  }

  deleteSuccess(){
    var url = devconfig.admin+"/webinar/remove-webinar";
    var data = JSON.stringify({
      "webinar_id" : this.state.deleteItem.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#removePrmoModal').modal('hide');
        this.clearFilter();
        this.upcomingApi(null, 1);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  clearFilter(){
    this.setState({ 
      filter: null,
      sort: null,
      filterDefault: {value: null, label: 'All Videos'},
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      categoryFilter: [],
      dataLoading: false,
      deleteItem: null
    });
  }

  render() {
    const filterBy = [
      { value: null, label: 'All Creators' },
      { value: 1, label: 'Top 10' },
      { value: 2, label: 'Muted' },
    ]
    const sortBy = [
      { value: 1, label: 'Name' },
      { value: 2, label: 'Latest scheduled' },
      { value: 3, label: 'Order of created' },
    ]

    return (
      <div className="">
        {/*
          !this.state.dataLoading && this.state.data.length ?
            <div className="row">
              <div className="col-lg-2">
                <div className="e-filter-wrap pt-2">
                  <label>Category:</label>
                  <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
              </div>
              <div className="col-lg-2 p-0">
                <div className="e-filter-wrap pt-2">
                  <label>Sort by:</label>
                  <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
                </div>
              </div>
            </div>
          :
            null
        */}

        <div className="row">
          <div className="col-lg-2">
            <div className="e-filter-wrap pt-2">
              <label>Category:</label>
              <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
            </div>
          </div>
          <div className="col-lg-2 p-0">
            <div className="e-filter-wrap pt-2">
              <label>Sort by:</label>
              <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
            </div>
          </div>
        </div>
        
        {
          this.state.dataLoading ?
            <div className="row mt-5">
              <div className="col-lg-12 text-center">
                <LazyLoaderGif/>
              </div>
            </div>
          :
            <div className="row mt-3">
            {
              this.state.data.length ?
                this.state.data.map( (data, index) => {
                  return(
                    <div className="col-lg-3 e-video-card-wrap mb-4" key={index}>
                      <div className="card">
                        <div className="position-relative">
                          <img className="card-img-top" src={data.thumbnail} alt="Card thumbnail"/>
                          <span className="e-video-duration">{data.duration}</span>
                          {
                            data.live ?
                              <span className="e-video-live">Live</span>
                            :
                              null
                          }
                        </div>
                        <div className="card-body">
                          <h5 className="card-title e-p-ellipsis-2">{data.name}</h5>
                          <p className="e-card-time">{data.scheduled_time}</p>
                          <span className="e-card-edit mr-3" onClick={this.editClick.bind(this, data)}></span>
                          <span className="e-card-link" onClick={this.addLinkClick.bind(this, data)}></span>
                          <span className="e-card-delete e-upcoming-delete" data-toggle="modal" data-target="#removePrmoModal" onClick={this.deleteClick.bind(this, data)}></span>
                        </div>
                      </div>
                    </div>
                  );
                })
              :
                <NothingFound data={"No upcoming videos found!"}/>
            }
          </div>
        }

        {/*Modals*/}
        <RemovePromo success={this.deleteSuccess.bind(this)}/>
      </div>
      
    );
  }
}

export default Upcoming;
