/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import EmailValidation from '../../services/validation/email';

/*Assets*/
import logo from '../../assets/images/logo.svg';
import loginHero from '../../assets/images/login-hero.svg';


class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      errorMessage: "",
      apiLoading: false,
      successMessage: ""
    }
  }

  componentWillMount() {
    
  }

  async buttonClick(e){
    e.preventDefault();
    this.setState({apiLoading: true})
    var validationSuccess = true;

    await EmailValidation(this.state.email).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid email address, please check and retry.", apiLoading: false});
        validationSuccess = false;
      }
    }.bind(this))

    if(validationSuccess){
      var url = devconfig.admin+"/auth/forgot-password";
      var data = JSON.stringify({
        "email" : this.state.email
      })
      APIPost(url, data).then(function(response){
        if(response.status){
          this.setState({successMessage: "Password reset link sent to your registered email.", apiLoading: false});
        }
        else{
          this.setState({errorMessage: response.message, apiLoading: false});
        }
      }.bind(this))
    }
  }

  render() {
    return (
      <section className="e-login-section">
        <div className="container-fluid">
          <div className="row e-login-logo-wrap">
            <div className="col-lg-12">
              <a href="/">
                <img src={logo} alt="AliceBlue Tradeschool logo"/>
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 pt-5">
            <div className="offset-lg-2 col-lg-2  e-login-img">
              <img src={loginHero} alt="Login hero"/>
            </div>
            <div className="col-lg-6 e-login-wrap">
              <h2>Forgot Password</h2>

              <form id="e-login-form">
                <div className="pt-4">
                  <Input label="Registered Email" id="u-email" type="text" defaultValue={this.state.email} valueChange={(tempVal) => this.setState({email: tempVal})} />
                  
                </div>
                <div className="d-flex justify-content-between">
                  {
                    this.state.successMessage.length ?
                      <span className="pt-2 text-success">{this.state.successMessage}</span>
                    :
                      null
                  }

                  <span className="pt-2 text-danger">{this.state.errorMessage}</span>
                  
                  
                  {
                    this.state.email.length && !this.state.apiLoading ?
                      <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Send OTP</button>
                    :
                      <button className="e-inactive" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Send OTP</button>
                  }
                </div>
              </form>
            </div>
          </div>
          <div className="e-copyright-wrap">
            © 2020 Alice Blue. All rights reserved. Powered by <a href="https://evoqueinnovativelab.com" target="_blank">Evoque Innovative Lab</a>
          </div>
        </div>
      </section>
      
    );
  }
}

export default ForgotPassword;
