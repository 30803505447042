/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import Upcoming from './upcoming';
import AllVideos from './allVideos';
import NothingFound from '../../components/common/nothingFound';

import AddPromo from '../../components/modals/addPromo';
import EditPromo from '../../components/modals/editPromo';
import AddPromoLink from '../../components/modals/addPromoLink';
import AddVideo from '../../components/modals/addVideo';

/*Assets*/
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';
import profile from '../../assets/images/profile.png';

class CreatorDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: null,
      data: null,
      upcomingTab: true,
      dataLoading: false,
      notFound: false,
      editUpcomingItem: null,
      addLinkItem: null
    }
  }

  componentDidMount() {
    var query = this.getUrlVars();
    this.setState({ id: query.id });
    this.profileApi(query.id);
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  profileApi(tempId){
    this.setState({ dataLoading: true});
    var url = devconfig.admin+"/auth/profile";
    
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ data: response.data, dataLoading: false});
      }
      else{
        this.setState({ data: null, dataLoading: false, notFound: true});
      }
    }.bind(this))
  }
  
  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.creatorsListApi(this.state.pageNum, filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.creatorsListApi(this.state.pageNum, this.state.filter, sort.value);
  }
  
  abbreviateNumber(num){
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
  }

  tabClick(tempStatus){
    this.setState({upcomingTab: tempStatus});
  }

  muteClick(tempStatus){
    var url = devconfig.admin+"/creator/mute-or-unmute";
    var data = JSON.stringify({
      "creator_id" : this.state.id,
      "mute" : tempStatus
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.profileApi(this.state.id);
        $('#muteModal').modal('hide');
        $('#unmuteModal').modal('hide');
      }
    }.bind(this))
  }

  removeCreatorClick(){
    var url = devconfig.admin+"/creator/remove-creator";
    var data = JSON.stringify({
      "creator_id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        window.location.href = '/';
      }
    }.bind(this))
  }

  addPromoSuccess(){
    this.setState({editUpcomingItem: null});
    this.profileApi(this.state.id);
  }

  editClick(tempData){
    this.setState({editUpcomingItem: tempData});
  }

  editUpcomingClose(){
    this.setState({editUpcomingItem: null});
  }

  addLinkClick(tempData){
    this.setState({addLinkItem: tempData});
  }

  addLinkClose(){
    this.setState({addLinkItem: null});
  }

  addLinkSuccess(){
    this.profileApi(this.state.id);
  }

  addVideoSuccess(){
    this.profileApi(this.state.id);
    this.setState({upcomingTab: false});
  }

  render() {
    if(this.state.data){
      var tempLink = "https://www.youtube.com/channel/"+this.state.data.channel_id;
    }
    
    if(this.state.notFound){
      return(
        <div className="e-main-content">
          <div className="row">
            <div className="col-lg-12 text-center">
              <NothingFound data="No data found !"/>
            </div>
          </div>
        </div>
      )
    }
    else{
      return (
        <div className="e-main-content e-invites-wrap">
          <h2>Webinars</h2>
          {
            !this.state.dataLoading ?
              <div className="row">
                <div className="col-lg-12">

                  {
                    this.state.data ?
                      <div className="media e-detail-profile-wrap">
                        <div className="media-body">
                          <div className="e-detail-profile-top">
                            <div className="d-flex justify-content-between">
                              <div>
                                <span className="e-detail-profile-counts-wrap mr-5">
                                  <span className="e-detail-profile-counts">{this.state.data.video_count}</span>
                                  <br/>
                                  <span className="e-detail-profile-item">Webinars</span>
                                </span>
                                <span className="e-detail-profile-counts-wrap">
                                  <span className="e-detail-profile-counts">{this.abbreviateNumber(this.state.data.views)}</span>
                                  <br/>
                                  <span className="e-detail-profile-item">Views</span>
                                </span>
                              </div>
                              <div>
                                <span className="e-channel-link mr-4"><a href={tempLink} target="_blank">View Channel</a></span>
                                <button className="e-p-btn mr-4" data-toggle="modal" data-target="#addPromoModal">New Webinar Promo</button>
                                <button className="e-s-btn mr-4" data-toggle="modal" data-target="#addVideoModal">Add Old Video</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                      null
                  }
                </div>
              </div>
            :
              <div className="row">
                <div className="col-lg-12">
                  <div className="e-detail-profile-wrap e-profile-placeholder text-center pt-5">
                     <LazyLoaderGif/>
                  </div>
                </div>
              </div>
          }
          <div className="row">
            <div className="col-lg-12 mt-5">
              {
                !this.state.dataLoading ?
                  <div className="e-detail-tab-wrap">
                    {
                      this.state.upcomingTab ?
                        <div>
                          <span className="mr-4 e-active" onClick={this.tabClick.bind(this, true)}>Upcoming Webinars</span>
                          <span onClick={this.tabClick.bind(this, false)}>All Videos</span>
                        </div>
                      :
                        <div>
                          <span className="mr-4" onClick={this.tabClick.bind(this, true)}>Upcoming Webinars</span>
                          <span className="e-active" onClick={this.tabClick.bind(this, false)}>All Videos</span>
                        </div>
                    }
                    
                  </div>
                :
                  null
              }
            </div>
          </div>
          {
            !this.state.dataLoading ?
              <div>
                {
                  this.state.upcomingTab ?
                    <Upcoming edit={this.editClick.bind(this)} addlink={this.addLinkClick.bind(this)}/>
                  :
                    <AllVideos/>
                }
              </div>
            :
              null
          }
          
          
          {/* Modal */}
          {
            this.state.data && !this.state.dataLoading && !this.state.editUpcomingItem ?
              <div>
                <AddPromo success={this.addPromoSuccess.bind(this)}/>
                <AddVideo success={this.addVideoSuccess.bind(this)}/>
              </div>
            :
              null
          }

          {
            this.state.editUpcomingItem ?
              <EditPromo data={this.state.editUpcomingItem} editclose={this.editUpcomingClose.bind(this)} success={this.addPromoSuccess.bind(this)}/>
            :
              null
          }

          {
            this.state.addLinkItem ?
              <AddPromoLink data={this.state.addLinkItem} addlinkclose={this.addLinkClose.bind(this)} success={this.addLinkSuccess.bind(this)}/>
            :
              null
          }

        </div>
      );
    }
  }
}

export default CreatorDetail;
