// const url= "http://localhost:5000/api/v1";

const dev= "https://j4b1yj8zg7.execute-api.ap-south-1.amazonaws.com/dev/api/v1";
// const prod= "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v1";
const stagging= "https://8bugtbiaji.execute-api.ap-south-1.amazonaws.com/staging/api/v1";

// prod url
const url = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v1";
// const url2 = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v2";


var devconfig = {
   // admin: stagging + "/creator",
   admin: url + "/creator",
   // admin: dev + "/creator",
}

module.exports = devconfig;