/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';


/*Components*/
import Input from '../../../components/formComponents/input';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';
import ImageUploader from '../../../components/common/imageUploader';
import UploadLoader from '../../../components/common/uploadLoader';

/*Assets*/

class EditBankModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      bank: "",
      branch: "",
      ifsc_code: "",
      description: "",
      apiLoading: false,
      scheduleTime: "",
      catFilterOption: [],
      langFilterOption: [],
      showUploadLoader: false,
      account_no: "",
      name: "",
      duration: null,
      selectedPriceDiscountValue: {value: null, label: 'No discount'}
    }
  }

  componentDidMount() {
    var params = this.props.data;

    if(params){
      this.setState({
        name: params.name,
        branch: params.branch,
        bank: params.bank,
        account_no: params.account_no,
        ifsc_code: params.ifsc_code,
      })
    }
  }

  updateBankDetails(){
    this.setState({apiLoading: true});
    var url = devconfig.admin + "/auth/update-bank-details";
    var data = JSON.stringify({
      name: this.state.name,
      branch: this.state.branch,
      bank: this.state.bank,
      account_no: this.state.account_no,
      ifsc_code: this.state.ifsc_code,
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        this.setState({apiLoading: false});
        window.location.reload();
      }
      else{
        this.setState({apiLoading: false, errorMessage: response.message});
      }
    })
  }

  render() {
    const {selectedValue, selectedLangValue, duration, value, selectedPriceDiscountValue} = this.state;

    const priceDiscounts = [
      { value: null, label: 'No discount' },
      { value: 1, label: '10%' },
      { value: 2, label: '20%' },
    ]
    
    return (
      <div className="modal fade e-modal" id="addBankModal" tabIndex="-1" role="dialog" aria-labelledby="addBankModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title py-3">Edit Bank Details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Benefeciary name" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Bank name" id="u-name" type="text" value={this.state.bank} valueChange={(tempVal) => this.setState({bank: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Account number" id="u-name" type="text" value={this.state.account_no} valueChange={(tempVal) => this.setState({account_no: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="IFSC code" id="u-name" type="text" value={this.state.ifsc_code} valueChange={(tempVal) => this.setState({ifsc_code: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Branch name" id="u-name" type="text" value={this.state.branch} valueChange={(tempVal) => this.setState({branch: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <div className="e-addPromo-btn-wrap text-right pt-2">
                    <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                    {
                      this.state.name.length && 
                      this.state.ifsc_code.length && 
                      this.state.branch.length && 
                      this.state.account_no.length && 
                      this.state.bank.length && 
                      !this.state.apiLoading ?
                        <button type="submit" form="e-login-form" onClick={this.updateBankDetails.bind(this)}>Update</button>
                      :
                        <button className="e-inactive" form="e-login-form" value="Submit">Update</button>
                    }
                  </div>
                </div>
              </div>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditBankModal;
