/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import logo from '../../assets/images/logo.svg';
import loginHero from '../../assets/images/login-hero.svg';


class Signup extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      apiLoading: false,
      params: {},
      confirmPassword: ""
    }
  }

  componentWillMount() {
    var query = this.getUrlVars();
    this.setState({params: query});
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  async buttonClick(e){
    e.preventDefault();
    this.setState({apiLoading: true})
    var validationSuccess = true;

    if(this.state.password != this.state.confirmPassword){
      this.setState({errorMessage: "Mismatch in password and confirm password.", apiLoading: false});
      validationSuccess = false;
    }

    if(validationSuccess){
      var url = devconfig.admin+"/auth/signup";
      var data = JSON.stringify({
        "password" : this.state.confirmPassword,
        "key" : this.state.params.key,
        "email" : this.state.params.email
      })
      APIPost(url, data).then(function(response){
        if(response.status){
          localStorage.setItem("u_data", JSON.stringify(response.data));
          localStorage.setItem("u_token", response.access_token);
          window.location.href = '/';
        }
        else{
          this.setState({errorMessage: response.message, apiLoading: false});
        }
      }.bind(this))
    }
  }

  render() {
    return (
      <section className="e-login-section">
        <div className="container-fluid">
          <div className="row e-login-logo-wrap">
            <div className="col-lg-12">
              <a href="/">
                <img src={logo} alt="AliceBlue Tradeschool logo"/>
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 pt-5">
            <div className="offset-lg-2 col-lg-2  e-login-img">
              <img src={loginHero} alt="Login hero"/>
            </div>
            <div className="col-lg-6 e-login-wrap">
              <h2>Create password to continue</h2>

              <form id="e-login-form">
                <div className="pt-4">
                  <Input label="Password" id="u-password" type="password" defaultValue={this.state.password} valueChange={(tempVal) => this.setState({password: tempVal})} />
                  <Input label="Confirm Password" id="u-confirmPassword" type="password" defaultValue={this.state.confirmPassword} valueChange={(tempVal) => this.setState({confirmPassword: tempVal})} />
                </div>
                <div className="d-flex justify-content-between">
                  <span className="pt-2 text-danger">{this.state.errorMessage}</span>
                  {
                    this.state.password.length && this.state.confirmPassword.length && !this.state.apiLoading ?
                      <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Submit</button>
                    :
                      <button className="e-inactive" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Submit</button>
                  }
                </div>
              </form>
            </div>
          </div>
          <div className="e-copyright-wrap">
            © 2020 Alice Blue. All rights reserved. Powered by <a href="https://evoqueinnovativelab.com" target="_blank">Evoque Innovative Lab</a>
          </div>
        </div>
      </section>
      
    );
  }
}

export default Signup;
