/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';

/*Assets*/

class AddPromoLink extends Component {
  constructor(props){
    super(props);
    this.state = {
      link: ""
    }
  }

  componentDidMount() {
    setTimeout(function(){
      $('#addLinkModal').modal('show');
    }.bind(this),100);
  }

  buttonClick(){
    this.setState({apiLoading: true});

    var url = devconfig.admin+"/webinar/add-live-link";
    var data = JSON.stringify({
      "video_id" : this.props.data.id,
      "link" : this.state.link
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({apiLoading: false});
        $('#addLinkModal').modal('hide');
        this.props.success();
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this))
  }

  addCloseClick(){
    this.props.addlinkclose();
  }

  render() {
    return (
      <div className="modal fade" id="addLinkModal" tabIndex="-1" role="dialog" aria-labelledby="addLinkModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Live Link</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.addCloseClick.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="e-modal-s-txt">Save the link for the live webinar</div>
            <div className="modal-body mt-3">
              <Input label="Webinar Link" id="u-lang" type="text" defaultValue={this.state.link} valueChange={(tempVal) => this.setState({link: tempVal})} />
              
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal" onClick={this.addCloseClick.bind(this)}>Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        this.state.link.length && !this.state.apiLoading ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save Link</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save Link</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default AddPromoLink;
