import $ from 'jquery';

export default async function APIPost(url, data) {
  return await $.ajax({
    type: "POST",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("u_token")
    },
    data: data
  })
  .then(function(response){
    if(response){
      return response
    }
  }.bind(this))

  .fail(function(xhr, textStatus, errorThrown){
    return textStatus
  }.bind(this))
}
