/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';


/*Components*/
import Input from '../../../components/formComponents/input';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';
import ImageUploader from '../../../components/common/imageUploader';
import UploadLoader from '../../../components/common/uploadLoader';

/*Assets*/

class CreateCourseModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      link: "",
      apiLoading: false,
      scheduleTime: "",
      catFilterOption: [],
      langFilterOption: [],
      showUploadLoader: false,
      imageURL: "",
      value: null,
      duration: null,
      discountOptions: [],
      selectedPriceDiscountValue: {value: null, label: 'No discount'},
      // selectedValue: {value: null, label: 'Choose category'},
      selectedLangValue: {value: null, label: 'Choose language'},
    }
  }

  componentDidMount() {
    var params = this.props.data;
    if(params){
      var selectedCategories = params.categories;
      var categories = []; 
      if(selectedCategories){
        for (var i = selectedCategories.length - 1; i >= 0; i--) {
          var item = selectedCategories[i];
          categories.push({
            label: item.category,
            value: item.id
          })
        }
      }
      this.setState({
        name: params.name,
        price: params.price,
        fPrice: params.offer,
        selectedValue: categories,
        selectedLangValue: {label: params.language, value: params.language_id},
        selectedPriceDiscountValue: {label: params.discount, value: params.discount_id},
        imageURL: params.image

      })
    }
    this.getCategory();
    this.getLanguage();
    this.getDiscounts();
  }

  getCategory(){
    var url = devconfig.admin + "/course-category/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });
        
        this.setState({catFilterOption: tempArry});
      }
    }.bind(this))
  }

  getLanguage(){
    var url = devconfig.admin + "/course-language/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.language};
          tempArry.push(item);
        });
        
        this.setState({langFilterOption: tempArry});
      }
    }.bind(this))
  }

  getDiscounts(){
    var url = devconfig.admin + "/course-discount/get-all";
    APIGet(url).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.discount};
          tempArry.push(item);
        });
        
        this.setState({discountOptions: tempArry});
      }
    }.bind(this))
  }

  catSelectChange = selectedValue => {
    this.setState({ selectedValue });
  };

  langSelectChange = selectedLangValue => {
    this.setState({ selectedLangValue });
  };

  discountSelectChange = selectedPriceDiscountValue => {
    var finalPrice = null;
    if(this.state.price){
      var discount = Number(selectedPriceDiscountValue.label);
      var discountAmount = Number(this.state.price) * (discount/100);
      var finalPrice = this.state.price - discountAmount;
      $(document).ready(()=>{
        $("#final-price").value = finalPrice;  
      })
      
      $("#final-price").value = finalPrice;
    }

    this.setState({ selectedPriceDiscountValue: selectedPriceDiscountValue, fPrice: finalPrice });
  };
  

  logoUploaded(tempURL){
    this.setState({imageURL: tempURL, showUploadLoader: false});
  }

  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
  }

  clearState(){
    this.setState({
      name: "",
      price: null,
      fPrice: null,
      imageURL: "",
      selectedPriceDiscountValue: {value: null, label: 'No discount'},
      selectedValue: {value: null, label: 'Choose category'},
      selectedLangValue: {value: null, label: 'Choose language'},
    })
  }

  imageSelected(){
    this.setState({showUploadLoader: true});
  }

  createCourse(){
    this.setState({errorMessage: "", apiLoading: true});
    let category_ids = this.state.selectedValue.map(a => a.value);
    console.log(category_ids)

    var tempFPrice = this.state.fPrice;
    if(!this.state.fPrice){
      tempFPrice = this.state.price;
    }

    var data = {
      name : this.state.name,
      category_ids : category_ids,
      language_id : this.state.selectedLangValue.value,
      discount_id : this.state.selectedPriceDiscountValue.value,
      price : Number(this.state.price),
      offer_price : Number(tempFPrice),
      image : this.state.imageURL
    }
    var url = devconfig.admin + "/course/create"

    APIPost(url, JSON.stringify(data)).then((response)=>{
      if(response.status){
        window.location.reload();
        this.setState({ apiLoading: false});
      }else{
        this.setState({ apiLoading: false, errorMessage: response.message});
      }
    })
  }

  render() {
    console.log("this.state.imageURL", this.state.imageURL)
    const {selectedValue, selectedLangValue, duration, value, selectedPriceDiscountValue} = this.state;
    const priceDiscounts = this.state.discountOptions;
    return (
      <div className="modal fade e-modal e-create-course-wrap" id="createCourseModal" tabIndex="-1" role="dialog" aria-labelledby="createCourseModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create Course</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Course Main Heading" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="e-add-video-select-wrap e-select-multi">
                    <Select isSearchable={false} options={this.state.catFilterOption} value={selectedValue} onChange={this.catSelectChange} isMulti={true} placeholder=""/>
                    <label>Choose Category</label>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="e-add-video-select-wrap">
                    <Select isSearchable={false} options={this.state.langFilterOption} value={selectedLangValue} onChange={this.langSelectChange} isMulti={false} placeholder=""/>
                    <label>Choose Language</label>
                  </div>
                </div>

                <div className="col-lg-6">
                  <Input label="Course Price" class="e-price-input" id="u-name" type="number" value={this.state.price} valueChange={(tempVal) => this.setState({price: tempVal})} />
                </div>
                <div className="col-lg-6">
                  <div className="e-add-video-select-wrap">
                    <Select isSearchable={false} options={priceDiscounts} value={selectedPriceDiscountValue} onChange={this.discountSelectChange} isMulti={false} placeholder=""/>
                    <label>Price discount in %</label>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="e-final-price-wrap">
                    <Input label="Final price" class="e-price-input e-final-price" id="final-price" type="number" value={this.state.fPrice} defaultValue={this.state.fPrice} valueChange={(tempVal) => this.setState({name: tempVal})}/>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      {
                        this.state.showUploadLoader ?
                          <div className="e-upload-btn text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                            <UploadLoader/>
                          </div>
                        :
                          this.state.imageURL.length ?
                            <div className="e-upload-btn e-image-preview-wrap text-center position-relative" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                              <img src={this.state.imageURL}/>
                              <label className="e-change">Change</label>
                            </div>
                          :
                            <div className="e-upload-btn text-center e-upload-main-btn" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                              <span>Click to upload <br/>(min 500*250 jpeg or png image)</span>
                            </div>
                            
                      }
                      <ImageUploader id="e-logo-upload" uploadSuccess={this.logoUploaded.bind(this)} imageSelected={this.imageSelected.bind(this)}/>
                    </div>
                    <div className="col-lg-6">
                      <div className="e-addPromo-btn-wrap text-right">
                        <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                        {
                          this.state.imageURL.length &&
                          this.state.selectedValue.length &&
                          // this.state.selectedPriceDiscountValue.value &&
                          this.state.selectedLangValue.value &&
                          this.state.name.length &&
                          !this.state.apiLoading ?
                            <button type="submit" form="e-login-form" onClick={this.createCourse.bind(this)}>Create Course</button>
                          :
                            <button className="e-inactive" form="e-login-form" value="Submit">Create Course</button>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default CreateCourseModal;
