/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class UploadLoader extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <span className="e-uploader-loader"></span>
    );
  }
}

export default UploadLoader;
