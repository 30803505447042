/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Assets*/
import logo from '../../assets/images/logo.svg';
import profile from '../../assets/images/profile.png';
import courseCover from '../../assets/images/course-cover.jpg';


class Sidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeItem: "",
      type: 1,
    }
  }

  componentWillMount() {
    this.findActiveItem();
    var tempLocal = JSON.parse(localStorage.getItem("u_data"));
    this.setState({type: tempLocal.type});

  }

  findActiveItem(){
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    this.setState({activeItem: tempUrl[tempUrl.length-1]});
  }

  navItemClick(item){
    this.setState({activeItem: item});
  }

  render() {
    return (
      <div className="e-sidebar">
        <div className="e-sidebar-logo px-4">
          <img src={logo} alt="Tradeschool logo"/>
        </div>
        <div className="e-sidebar-list-wrap p-4">
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "webinars" || this.state.activeItem === "" ?
                <Link className="e-sidebar-creators e-active" onClick={this.navItemClick.bind(this, "webinars")} to="/webinars">Webinars</Link>
              :
                <Link className="e-sidebar-creators" onClick={this.navItemClick.bind(this, "webinars")} to="/webinars">Webinars</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "webinar-users" ?
                <Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "webinar-users")} to="/webinar-users">Webinar Leads</Link>
              :
                <Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "webinar-users")} to="/webinar-users">Webinar Leads</Link>
            }
          </div>
          {
            this.state.type != 1 ?
              <div className="e-sidebar-item pb-4">
                {
                  this.state.activeItem === "courses" ?
                    <Link className="e-sidebar-courses e-active" onClick={this.navItemClick.bind(this, "courses")} to="/courses">Courses</Link>
                  :
                    <Link className="e-sidebar-courses" onClick={this.navItemClick.bind(this, "courses")} to="/courses">Courses</Link>
                }
              </div>
            :
              null
          }
          
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "profile" ?
                <Link className="e-sidebar-profile e-active" onClick={this.navItemClick.bind(this, "profile")} to="/profile">Profile</Link>
              :
                <Link className="e-sidebar-profile" onClick={this.navItemClick.bind(this, "profile")} to="/profile">Profile</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "invoices" ?
                <Link className="e-sidebar-profile e-active" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Payment Report</Link>
              :
                <Link className="e-sidebar-profile" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Payment Report</Link>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default Sidebar;
