/*Package importing*/
import React, { Component } from 'react';

/*Assets and Components*/
import noResultImg from '../../assets/images/empty.svg';

class NothingFound extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="col-lg-12 pt-0 text-center mt-5 e-no-result-wrap">
        <img className="e-no-result" src={noResultImg} alt="No data found"/>
        <div className="e-noData-txt">{this.props.data ? this.props.data : "Nothing found"}</div>
      </div>
    );
  }
}

export default NothingFound;
