/*Package importing*/
import React, { Component } from 'react';
import Select from 'react-select';

/*Import custom components and assets*/

class SelectBox extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
    this.setState({ selectedValue: this.props.defaultValue});
  }

  selectChange = selectedValue => {
    this.setState({ selectedValue });
    this.props.selectchange(selectedValue)
  };



  render() {
    const {selectedValue} = this.state;
    return (
      <Select isSearchable={false} options={this.props.options} value={selectedValue} onChange={this.selectChange} isMulti={this.props.selectType} placeholder={this.props.placeholder}/>
      
    );
  }
}

export default SelectBox;
