/*Import Packages*/
import React, { Component } from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
 
/*Import pages*/ 
import Login from './container/auth/login.js';
import Signup from './container/auth/signup.js';
import ForgotPassword from './container/auth/forgotPassword.js';
import ResetPassword from './container/auth/resetPassword.js';
import UsersList from './container/users/usersList.js';
import CreatorDetail from './container/webinars/detail.js';

/*Version - 2*/
import AllCourses from './container/courses/courseList.js';
import CourseDetail from './container/courses/courseDetail.js';
import Profile from './container/profile/main.js';
import PaymentReport from './container/invoice/invoice.js';
 
/*Import global components like header, footers*/
import Sidebar from './components/navigation/sidebar.js';
import Topbar from './components/navigation/topbar.js';
 
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
 
    }
  }
 
  render() {
    if(localStorage.getItem("u_token")){
      return(
        <BrowserRouter>
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 e-sidebar-wrap p-0">
                  <Sidebar/>
                </div>
                <div className="col-lg-10 p-0">
                  <Topbar/>
                  <Route exact path="/" render={() => (
                    <Redirect to="/webinars"/>
                  )}/>
                  <Route path="/webinar-users" exact component={UsersList}/>
                  <Route path="/webinars" exact component={CreatorDetail}/>
                  <Route path="/courses" exact component={AllCourses}/>
                  <Route path="/course-detail" exact component={CourseDetail}/>
                  <Route path="/profile" exact component={Profile}/>
                  <Route path="/invoices" exact component={PaymentReport}/>
                </div>
              </div>
            </div>
          </section>
        </BrowserRouter>
      )
    }
    else{
      return(
        <BrowserRouter>
          <div>
            <Route path="/" exact component={Login}/>
            <Route path="/signup" exact component={Signup}/>
            <Route path="/forgot-password" exact component={ForgotPassword}/>
            <Route path="/reset-password" exact component={ResetPassword}/>

          </div>
        </BrowserRouter>
      )
    }
  }
}
 
export default App;